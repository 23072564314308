export const initialState = {
  auth: {
    email: "",
    password: "",
  },
  isUploadingWeb: [],
  documents: [],
  headers: [],
  docData: [],
  partitionedDocuments: [],
  parsedDocument: {},
  type: {
    value: "",
    label: "",
  },
  programCode: "",
  option: "",
  headerKey: { value: "", label: "" },
  client: [],
  startDate: {
    year: { value: "", label: "" },
    month: { value: "", label: "" },
    date: { value: "", label: "" },
  },
  endDate: {
    year: { value: "", label: "" },
    month: { value: "", label: "" },
    date: { value: "", label: "" },
  },
  duplicateOrders: [],
  updateOrders: [],
  modal: {
    isCard: false,
    isFullheight: false,
    isActive: false,
    content: null,
    heading: "",
    headerClass: null,
    hideCloseButton: true,
    background: {
      color: null,
      opacity: "100",
    },
  },
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
  bottomNotifications: {
    isActive: true,
  },
}
