import { initialState } from "./initialState"

const RESET_DETAILS = "RESET_DETAILS"

const modal = {
  SHOW_MODAL: "SHOW_MODAL",
  HIDE_MODAL: "HIDE_MODAL",
}

const type = {
  SAVE_TYPE: "SAVE_TYPE",
  SAVE_OPTION: "SAVE_OPTION",
  SAVE_CLIENT: "SAVE_CLIENT",
  SAVE_START_DATE: "SAVE_START_DATE",
  IS_UPLOADING_WEB: "IS_UPLOADING_WEB",
  SAVE_MIGRATION_TAG: "SAVE_MIGRATION_TAG",
  SAVE_END_DATE: "SAVE_END_DATE",
  SAVE_HEADER_KEY: "SAVE_HEADER_KEY",
  SAVE_HEADERS: "SAVE_HEADERS",
  SAVE_PROGRAM_CODE: "SAVE_PROGRAM_CODE",
  SAVE_DUPLICATE_HEADERS: "SAVE_DUPLICATE_HEADERS",
}

const documents = {
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
  SAVE_DOCUMENT: "SAVE_DOCUMENT",
  SAVE_DOCUMENT_DATA: "SAVE_DOCUMENT_DATA",
  REMOVE_DOCUMENT: "REMOVE_DOCUMENT",
  SAVE_PARSED: "SAVE_PARSED",
  SAVE_PARTITIONED_DOCUMENT: "SAVE_PARTITIONED_DOCUMENT",
}

const bottomNotifications = {
  CLOSE_NOTIFICATIONS: "CLOSE_NOTIFICATIONS",
}

const toast = {
  SHOW_TOAST: "SHOW_TOAST",
  HIDE_TOAST: "HIDE_TOAST",
}

export let AppReducer = (state, action) => {
  switch (action.type) {
    case documents.SAVE_DOCUMENTS:
      return {
        ...state,
        documents: [...action.payload],
      }

    case documents.SAVE_PARSED:
      return {
        ...state,
        parsedDocument: action.payload,
      }

    case documents.SAVE_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      }

    case documents.SAVE_DOCUMENT_DATA:
      return {
        ...state,
        docData: action.payload,
      }
    case type.IS_UPLOADING_WEB:
      return {
        ...state,
        isUploadingWeb: action.payload,
      }
    case documents.REMOVE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter(
          (document) => action.payload.path !== document.path
        ),
      }

    case type.SAVE_PROGRAM_CODE:
      return { ...state, programCode: action.payload }

    case modal.SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }

    case type.SAVE_HEADERS:
      return {
        ...state,
        headers: action.payload,
      }
    case type.SAVE_CLIENT:
      return {
        ...state,
        client: action.payload,
      }
    case type.SAVE_MIGRATION_TAG:
      return {
        ...state,
        migrationTag: action.payload,
      }

    case type.SAVE_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      }

    case documents.SAVE_PARTITIONED_DOCUMENT:
      return {
        ...state,
        partitionedDocuments: action.payload,
      }

    case type.SAVE_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      }
    case type.SAVE_TYPE:
      return { ...state, type: action.payload }

    case type.SAVE_OPTION:
      return { ...state, option: action.payload }

    case type.SAVE_HEADER_KEY:
      return { ...state, headerKey: action.payload }

    case type.SAVE_DUPLICATE_HEADERS:
      return { ...state, duplicateOrders: action.payload }

    case bottomNotifications.CLOSE_NOTIFICATIONS:
      return {
        ...state,
        bottomNotifications: { ...state.bottomNotifications, isActive: false },
      }

    case modal.HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal, isActive: false } }

    case toast.SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } }

    case toast.HIDE_TOAST:
      return { ...state, toast: { ...initialState.toast } }

    case RESET_DETAILS:
      return { ...initialState }

    default:
      return { ...initialState }
  }
}
