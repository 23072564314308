// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-auth-auth-js": () => import("./../../../src/components/Auth/Auth.js" /* webpackChunkName: "component---src-components-auth-auth-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archiver-js": () => import("./../../../src/pages/archiver.js" /* webpackChunkName: "component---src-pages-archiver-js" */),
  "component---src-pages-delete-js": () => import("./../../../src/pages/delete.js" /* webpackChunkName: "component---src-pages-delete-js" */),
  "component---src-pages-extractor-js": () => import("./../../../src/pages/extractor.js" /* webpackChunkName: "component---src-pages-extractor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-validator-js": () => import("./../../../src/pages/validator.js" /* webpackChunkName: "component---src-pages-validator-js" */)
}

